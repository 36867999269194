@keyframes fadeIt {
  0% {
    background-color: #ad301b;
  }

  100% {
    background-color: #ffffff;
  }
}
.backgroundAnimatedConReporte {
  background-image: none !important;
  animation: fadeIt 60s ease-in-out;
}

@keyframes fadeIt1 {
  0% {
    background-color: #00b539;
  }

  100% {
    background-color: #ffffff;
  }
}
.backgroundAnimatedSinReporte {
  background-image: none !important;
  animation: fadeIt1 5s ease-in-out;
}
