.cuerpo {
  display: block;

  margin-top: 50px;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 2;
  padding: 10px;
}

@media (min-width: 600px) {
  .cuerpo {
    display: flex;
    margin-left: 230px;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 2;
  }
}

.cuerpo_detalle {
  display: block;
  margin-top: 100px;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 2;
  padding: 10px;
}

@media (min-width: 600px) {
  .cuerpo_detalle {
    display: block;
    margin-top: 50px;
    margin-left: 230px;

    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 2;
  }
}
