.base {
  font-size: larger;
  color: black;
  margin-top: 70px;
  margin-left: 250px;
}

.tabla {
  height: 550px;
  max-width: 100%;
}

@media (max-width: 600px) {
  .base {
    font-size: larger;
    color: black;
    margin-top: 70px;
    margin-left: 10px;
  }
}
