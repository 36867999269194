.mapa {
  height: auto;
  width: auto;
  min-width: 200px;
  min-height: 500px;
  margin: 10px;
}
.papelmapa {
  flex: 2 1 300px;
  margin: 20px;
  min-width: 300px;
  align-items: center;
}
