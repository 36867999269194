.linkMenu {
  text-decoration: none;
  color: black;
  z-index: -1;
}
.permanente {
  position: fixed;
  z-index: 1200;
  height: 60px;
  margin-left: 80px;
  margin-top: 2px;
}
