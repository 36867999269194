.ubicacion {
  margin-top: 80px;
  margin-left: 20px;
  margin-right: 20px;
  min-width: 200px;
}

.encabezado {
  display: flex;
  flex-direction: row-reverse;
  align-content: stretch;
}
.selector {
  width: 80%;
}
.boton {
  height: 55px;
  width: 20%;
  margin: 30px;
}

@media (min-width: 600px) {
  .ubicacion {
    margin-top: 80px;
    margin-left: 250px;
    margin-right: 50px;
    min-width: 200px;
  }
}
